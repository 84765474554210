export interface ILang {
  lang: TLang;
}

export type TLang = "en" | "ru";

export type TLinks = "services" | "workflow" | "projects" | "blog";

export interface IMarkdownFeedQuery {
  site: {
    siteMetadata: ISiteMetadata
  },
  allMarkdownRemark: {
    edges: Array<{
      node: {
        id: string;
        frontmatter: {
          title: string;
          date: string;
          authorAlias: string;
          keywords: string;
        };
        fields: {
          sourceName: string;
        };
        excerpt: string;
      };
    }>;
  };
};

export interface IBasePageContext extends ILang {
  site: ISiteMetadata;
}

export interface IMdQueryResult<T extends object> {
  allMarkdownRemark: {
    nodes: T[];
  };
}

export interface ISiteMetadata {
  title: string;
  description: string;
  siteUrl: string;
  image: string;
  analytics: { gtagId: string; clarityId: string };
  commentsSetup: ICommentsSetup;
}

export interface ICommentsSetup {
  repo: `${string}/${string}`;
  repoId: string;
  categoryId: string;
}

export enum Theme {
  dark = "dark",
  light = "light",
}

import React, { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosResponse } from "axios";

import {
  IGetPortfolioForm,
  TValidationMarkup,
} from "../../types/commonMarkup.type";
import { IGetPortfolioFormData } from "../../types/form.type";

import { getPortfolioFormSchema } from "../../tools/formSchemas";
import { hubspotApi } from "../../tools/api";
import { getCookie } from "../../tools/cookieGetter";
import SkeletonStub from "../common/skeletonStub";
import {
  getValidationMessage,
  mapGetPorfolioData,
} from "../../tools/formsTools";
import { StyledGetPortfolioTextInput } from "./inputs";
import ReCAPTCHA from "react-google-recaptcha";

const GetPortfolioForm = ({
  markup,
  validationMarkup,
}: {
  markup: IGetPortfolioForm;
  validationMarkup?: TValidationMarkup;
}) => {
  const [isPending, setIsPending] = useState<boolean>(false);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IGetPortfolioFormData>({
    mode: "onBlur",
    resolver: yupResolver(getPortfolioFormSchema),
  });

  const onSubmit: SubmitHandler<IGetPortfolioFormData> = async (data) => {
    if (!recaptchaRef.current) return;

    const token = await recaptchaRef.current.executeAsync();
    if (!token) {
      console.error("ReCAPTCHA check expired");
      return;
    }

    setIsPending(true);

    const hutk = getCookie("hubspotutk");

    const requestBody = mapGetPorfolioData(data, hutk);

    try {
      const response = await hubspotApi.post<
        any,
        AxiosResponse<{ inlineMessage: string }>,
        any
      >("/0e8123a1-58ac-4be1-90d8-b7193a9fc124", requestBody);

      if (response.status === 200) reset();
    } catch (e: any) {
      console.log("request err>>", e.response?.data?.message);
    }

    setIsPending(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="text-secondary-100 flex flex-col gap-y-5"
    >
      <p className="text-2xl font-light w-full max-w-72 whitespace-normal">
        {markup.title}
      </p>
      <div className="flex gap-5 items-center w-full lg:w-max flex-col lg:flex-row">
        <div className="flex flex-col gap-3 w-full lg:w-80">
          <StyledGetPortfolioTextInput
            id="firstname"
            label={markup.name}
            variant="outlined"
            error={!!errors["firstname"]?.message}
            helperText={getValidationMessage(
              "firstname",
              errors,
              validationMarkup
            )}
            aria-label="firstname"
            fullWidth
            size="small"
            {...register("firstname")}
          />
          <StyledGetPortfolioTextInput
            id="email"
            label={markup.email}
            variant="outlined"
            error={!!errors["email"]?.message}
            helperText={getValidationMessage("email", errors, validationMarkup)}
            aria-label="email"
            fullWidth
            size="small"
            {...register("email")}
          />
          {/* hack for the automatic habspot form collector for ignore this form */}
          <StyledGetPortfolioTextInput
            id="amex"
            label="amex"
            aria-label="amex"
            fullWidth
            style={{display: "none"}}
            size="small"
          />
          <ReCAPTCHA 
            ref={recaptchaRef}
            badge="bottomleft"
            sitekey="6LeqV4wqAAAAAMycXF-7Gcujzm9i-LPbTIPu1q90"
            size="invisible"
            style={{display: "none"}}
          />
        </div>
        <button
          type="submit"
          disabled={!isValid || isPending}
          className="bg-main-70 px-8 rounded-full text-sm font-bold tracking-wide h-12 whitespace-nowrap relative hover:brightness-75 text-primary-70 bg-primary-30"
        >
          {isPending ? <SkeletonStub /> : null}
          <span className="z-20">{markup.buttonLabel}</span>
        </button>
      </div>
    </form>
  );
};

export default GetPortfolioForm;

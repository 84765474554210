import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

const NavLink = ({
  path,
  link,
  title,
  className,
}: {
  path: string;
  link: string;
  title: string;
  className?: string;
}) => {
  // isCurrent is set by useState + useEffect in order to avoid production errors that ssr styles doesn't match client render
  const [isCurrent, setIsCurrent] = useState<boolean>(false);
  useEffect(() => {
    setIsCurrent(path?.includes(linkPath));
  }, [path]);

  const linkPath = `${link.toLowerCase().split(" ").join("-")}`;

  return (
    <li>
      <Link
        to={`/${linkPath}`}
        aria-label={`CodeCave ${title.toLowerCase()}`}
        itemProp="url"
        className={`group text-lg font-semibold ${isCurrent ? "text-secondary-100" : "text-secondary-70"} hover:text-secondary-100 relative ${className}`}
      >
        <span itemProp="name">{title.toUpperCase()}</span>
      </Link>
    </li>
  );
};

export default NavLink;

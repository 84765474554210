import { Link } from "gatsby";
import React from "react";

const DiscussProjectButton = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}) => {
  return (
    <Link
      to="/#contact-form"
      aria-label={title}
      itemProp="url"
      className={`px-0 sm:px-5 md:px-6 font-bold whitespace-nowrap rounded-full mx-0 bg-btn-primary text-secondary-100 h-12 flex items-center justify-center hover:bg-main-70 ${className}`}
    >
      <span itemProp="name">{title}</span>
    </Link>
  );
};

export default DiscussProjectButton;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { ILayoutMarkupData } from "../../types/commonMarkup.type";
import { ILang, TLang } from "../../types/common.type";
import { Helmet } from "react-helmet";

const getAddress = (lang: TLang): string => {
  const requisitesData = useStaticQuery(query) as {
    allMarkdownRemark?: {
      nodes?:
        | {
            fields: { language: string };
            frontmatter: { layout: ILayoutMarkupData };
          }[]
        | null;
    };
  };

  const requisites = requisitesData?.allMarkdownRemark?.nodes?.find(
    (node) => node.fields.language === lang
  )?.frontmatter?.layout?.requisites;

  const address = requisites
    ? Object.entries(requisites)
        .map(([_, value]) => value)
        .join(" ")
    : "";

  return address;
};

export const LocalBusinessMetadata = ({
  itemProp,
  lang,
}: {
  itemProp?: string;
  lang: TLang;
}) => {
  const address = getAddress(lang);

  return (
    <div
      className="hidden"
      itemProp={itemProp}
      itemScope
      itemType="https://schema.org/LocalBusiness"
    >
      <meta itemProp="sameAs" content="https://facebook.com/codecavepro" />
      <meta itemProp="sameAs" content="https://youtube.com/c/CodeCavePro" />
      <meta itemProp="sameAs" content="https://twitter.com/codecavepro" />
      <meta itemProp="sameAs" content="https://vk.com/codecavepro" />
      <meta itemProp="sameAs" content="https://github.com/CodeCavePro" />
      <meta itemProp="sameAs" content="https://instagram.com/codecave.pro" />
      <meta
        itemProp="sameAs"
        content="https://linkedin.com/company/codecavepro"
      />

      <meta itemProp="name" content="CodeCave" />
      <meta itemProp="currenciesAccepted" content="USD, EUR" />
      <meta itemProp="openingHours" content="Mo-Fr 09:00-18:00" />
      <meta
        name="description"
        content="Outsourcing software and web development"
      />
      <meta name="image" content="/logo.svg" />
      <meta itemProp="address" content={address} />
      <meta name="address" content={address} />
      <meta property="address" content={address} />
    </div>
  );
};

export const BasicMetadata = ({ lang }: ILang) => {
  const address = getAddress(lang);

  return (
    <>
      <meta itemProp="sameAs" content="https://facebook.com/codecavepro" />
      <meta itemProp="sameAs" content="https://youtube.com/c/CodeCavePro" />
      <meta itemProp="sameAs" content="https://twitter.com/codecavepro" />
      <meta itemProp="sameAs" content="https://vk.com/codecavepro" />
      <meta itemProp="sameAs" content="https://github.com/CodeCavePro" />
      <meta itemProp="sameAs" content="https://instagram.com/codecave.pro" />
      <meta
        itemProp="sameAs"
        content="https://linkedin.com/company/codecavepro"
      />

      <meta itemProp="name" content="CodeCave" />
      <meta
        name="description"
        content="Outsourcing software and web development"
      />
      <meta name="og:image" content="/logo.svg" />
      <meta itemProp="address" content={address} />
      <meta property="address" content={address} />
      <meta name="address" content={address} />
    </>
  );
};

interface HeadSeoProps extends ILang {
  title: string;
  description?: string;
  img?: string;
  siteUrl: string;
  path: string;
  canonicalUrl?: string;
}

export const HeadSeo = ({
  title,
  description,
  img,
  siteUrl,
  path,
  lang,
  canonicalUrl,
}: HeadSeoProps) => {
  const descriptionText = description ?? title;
  const pageUrl = `${siteUrl}${path}`;
  const imgUrl = img ? `${siteUrl}${img}` : `${siteUrl}/siteImage.png`;
  const locale = lang === "ru" ? "ru_RU" : "en_US";

  return (
    <Helmet
      title={title}
    >
      {canonicalUrl && <link rel="canonical" key={canonicalUrl} href={canonicalUrl}/>}
      <link rel="shortcut icon" href={`/favicon.ico`} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/favicon-16x16.png`}
      />
      <link rel="manifest" href={`/site.webmanifest`} />
      <link rel="mask-icon" href={`/safari-pinned-tab.svg`} color="#5bbad5" />
      <link rel="icon" sizes="16x16 32x32 64x64" href={`/favicon.ico`} />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="msapplication-TileImage" content={`/mstile-150x150.png`} />

      <meta name="title" content={title} />
      <meta name="description" content={descriptionText} />
      <meta property="image" content={imgUrl} />
      <meta itemProp="image" content={imgUrl} />
      <meta property="url" content={pageUrl} />

      <meta property="og:locale" content={locale} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={descriptionText} />
      <meta property="og:image" content={imgUrl} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={pageUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={descriptionText} />
      <meta property="twitter:image" content={imgUrl} />

      <meta name="yandex-verification" content="2f1c8394b4dad72a" />
    </Helmet>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "common" } } }
      sort: { frontmatter: { order: ASC } }
    ) {
      nodes {
        fields {
          language
        }
        frontmatter {
          layout {
            requisites {
              ein
              companyCode
              street
              city
              postalCode
              country
              phone
            }
          }
        }
      }
    }
  }
`;

import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { Theme } from "../../types/common.type";

export const StyledGetPortfolioTextInput = styled(TextField)({
  "& label": {
    color: "#E4E4E4",
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  "& label.Mui-focused": {
    color: "#E4E4E4",
  },
  "& label.Mui-error": {
    color: "#E4E4E4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2D2D2D",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#E4E4E4",
    "&:hover input": {
      borderRadius: "10px",
      backgroundColor: "#2D2D2D",
    },
    "& fieldset": {
      borderColor: "#2D2D2D",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
      borderRadius: "10px",
    },

    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderRadius: "10px",
    },
  },
});

export const ContactFormInput = styled(TextField)((props) => {
  const textColor = props.theme === Theme.light ? "var(--color-main-100)" : "var(--color-secondary-100)";
  const borderColor = "var(--color-main-70)";
  const backgroundColor = props.theme === Theme.light ? "var(--color-secondary-100)" : "var(--color-main-30)"

  return {
  "& label": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
  },
  "& label, & label.Mui-focused, & label.Mui-error, &::placeholder, & .MuiSvgIcon-root": {
    color: textColor,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: borderColor,
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    color: textColor,
    backgroundColor: backgroundColor,
    borderRadius: "10px",
    "& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
      borderRadius: "10px",
      border: `2px ${borderColor} solid`
    },
  },
}});

export const ControlLabel = styled(FormControlLabel)({
  "& span.MuiFormControlLabel-label": {
    fontSize: "12px",
    lineHeight: "12px",
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
  },
  "& span.MuiCheckbox-root": {
    padding: "0px 8px 0px 9px",
  },
  "& .MuiSvgIcon-root": { fontSize: 20 },
});

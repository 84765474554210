import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";

import { ILayoutMarkupData } from "../../types/commonMarkup.type";

import { BsGridFill } from "@react-icons/all-files/bs/BsGridFill";

import NavLink from "./navLink";
import DiscussProjectButton from "./discussProjectButton";

interface INavbarProps {
  path: string;
  layoutMarkup: ILayoutMarkupData;
}

const Navbar = ({ path, layoutMarkup }: INavbarProps) => {
  const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false);
  const ref = useRef<HTMLUListElement>(null);

  const closeBurgerMenu = () => setIsBurgerOpen(false);

  useEffect(() => {
    if (!window) return;
    ref.current?.addEventListener("click", closeBurgerMenu, {
      capture: true,
    });

    return () => ref.current?.removeEventListener("click", closeBurgerMenu);
  }, []);

  return (
    <nav className="sticky self-center w-[75%] max-w-[1280px] min-w-fit rounded-full mt-5 top-5 z-50 bg-main-70 blur-bg">
      <div
        className="flex w-full mx-auto px-6 md:pr-2 lg:pl-10 max-w-screen-xl mx-auto w-full relative h-16 items-center justify-between"
      >
        {/* main links */}
        <div className="flex w-full items-center justify-between">
          <Link to="/" aria-label={layoutMarkup.homePageLinkLabel}>
            <img
              src="/logo.svg"
              alt="logo"
              itemProp="image"
              className="w-20 sm:w-24 min-w-[5rem] object-contain"
            />
          </Link>
          <ul
            className="hidden md:flex w-max justify-between space-x-10 mx-5"
            itemScope
            itemType="https://schema.org/SiteNavigationElement"
          >
            {Object.entries(layoutMarkup.links).map(([link, title]) => (
              <NavLink key={link} path={path} link={link} title={title} />
            ))}
          </ul>
          <DiscussProjectButton
            title={layoutMarkup.discussProject}
            className="hidden md:flex"
          />
        </div>

        {/* burger button */}
        <button
          type="button"
          className="flex items-center justify-center text-primary-70 md:hidden"
          aria-controls="mobile-menu"
          aria-expanded="false"
          aria-label="menu"
          onClick={() => setIsBurgerOpen(!isBurgerOpen)}
        >
          <BsGridFill className="text-4xl" />
        </button>
        {/* mobile main links */}

        <div className="absolute right-0 top-full z-50 bg-main-70 blur-bg rounded-2xl w-full">
          <ul
            className={`md:hidden relative w-full flex flex-col p-2.5 gap-2 rounded-2xl ${
              !isBurgerOpen ? "hidden" : ""
            }`}
            ref={ref}
            id="mobile-menu"
            itemScope
            itemType="https://schema.org/SiteNavigationElement"
          >
            {Object.entries(layoutMarkup.links).map(([link, title]) => (
              <NavLink
                key={link}
                path={path}
                link={link}
                title={title}
                className="h-12 w-full border border-solid border-main-100 flex items-center justify-center rounded-md"
              />
            ))}
            <li>
              <DiscussProjectButton title={layoutMarkup.discussProject} />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
